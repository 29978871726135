import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import './login.scss'

function NewLogin() {
  
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams();
  
  const callSetCookiesApi = async () => {
    const token = searchParams.get('token')
    const backendApiUrl = searchParams.get('backendApiUrl')
    if(token && backendApiUrl) {
      const rawResponse = await fetch('https://avaai-be.avawatz.com/auth/setAuthCookies', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({genieToken: token, genieBackendApiUrl: backendApiUrl})
      });
      
      const res = await rawResponse.json();
      if(res)  {
        localStorage.setItem('genieBackendApiUrl', backendApiUrl)
        navigate('/projects')
      }      
    } else {
      navigate('/projects')
    }
  }
  
  useEffect(() => {    
    callSetCookiesApi()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <>
      <div className='zoom-container'>
        <img src="/genie-logo.png" alt="Genie Logo" width={300}/>
      </div>
    </>
  )
}

export default NewLogin